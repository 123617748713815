<template>
  <form class="space-y-4">
    <div>
      <label>Card Number</label>
      <div
        id="card-number"
        class="field"
        :class="fieldClasses"
      ></div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
      <div>
        <label>Card Expiry</label>
        <div
          id="card-expiry"
          class="field"
          :class="fieldClasses"
        ></div>
      </div>

      <div>
        <label>Card CVC</label>
        <div
          id="card-cvc"
          class="field"
          :class="fieldClasses"
        ></div>
      </div>

      <div class="flex items-end justify-center md:justify-end">
        <stripe-logo />
      </div>
    </div>

    <div id="card-error">
      {{ tokenError }}
    </div>

    <slot
      name="action-btn"
      :submit="submit"
      :formReady="formReady"
      :creatingCard="creatingCard"
      :close="closeModal"
    />
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BbLoader from '@/components/loader/BBLoader'
import StripeLogo from '@/components/shared/logo/StripeLogo'
import Toast from '@/components/shared/Toast'

export default {
  name: 'card-form',
  components: { StripeLogo, BbLoader, Toast },
  emits: ['cancel', 'submitted'],
  props: {
    parent: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: null,
        number: null,
        expiry: null,
        cvc: null,
      },
      numberReady: false,
      expiryReady: false,
      cvcReady: false,
      creatingCard: false,
      tokenError: null,
      stripeInstance: null,
      intentID: null,
      returnURL: null,
    }
  },
  computed: {
    ...mapGetters({
      storeId: 'store/selectedStoreId',
    }),
    formReady() {
      return this.numberReady && this.expiryReady && this.cvcReady
    },
    fieldClasses() {
      return this.formReady ? null : 'bg-bb-pale-grey'
    },
  },
  methods: {
    ...mapActions({
      loadCards: 'card/loadCards',
    }),
    closeModal() {
      this.$emit('cancel')
    },
    async initialiseForm() {
      const response = await this.$http.get(`card/init-save`)

      this.intentID = response.data.data.setup_intent_id
      this.stripeInstance = new Stripe(process.env.VUE_APP_STRIPE_KEY)
      const elements = this.stripeInstance.elements({ clientSecret: response.data.data.client_secret })
      this.form.number = elements.create('cardNumber', { showIcon: true })
      this.form.number.on('ready', _ => (this.numberReady = true))
      this.form.number.mount('#card-number')

      this.form.expiry = elements.create('cardExpiry')
      this.form.expiry.on('ready', _ => (this.expiryReady = true))
      this.form.expiry.mount('#card-expiry')

      this.form.cvc = elements.create('cardCvc')
      this.form.cvc.on('ready', _ => (this.cvcReady = true))
      this.form.cvc.mount('#card-cvc')
    },
    async submit(e) {
      if (!this.formReady || this.creatingCard) {
        return
      }

      this.creatingCard = true
      this.tokenError = null

      // Fetch card token from Stripe
      const { token, error } = await this.stripeInstance.createToken(this.form.number)
      const intentID = this.intentID

      if (error) {
        this.tokenError = error.message
        this.creatingCard = false
        return
      }

      this.$gtm.trackEvent({
        event: 'card_form_submit',
        event_source_content: e.target.innerText,
        event_source: 'card_form',
        project: 'console',
      })

      // Send card token to API
      try {
        this.returnURL = 'dashboard'
        if (this.$route.name == 'account:billing:cards') {
          this.returnURL = 'complete'
        }
        const res = await this.$http.post('card', {
          token: token.id,
          setup_intent_id: intentID,
          return_url: this.returnURL,
          store_id: this.storeId,
        })

        // If not succeeded
        if (res?.data?.data?.redirect_to_url) {
          window.location.replace(res.data.data.redirect_to_url.url)
          return
        }

        await this.loadCards()
        this.$emit('submitted')

        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'A new credit card has been added to your account!',
            type: 'success',
          },
        })
      } catch (err) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: err.response.data.error,
            type: 'error',
          },
        })
      } finally {
        this.creatingCard = false
      }
    },
  },
  beforeMount() {
    this.initialiseForm()
  },
  mounted() {
    this.$gtm.trackEvent({
      event: 'card_form_open',
      event_source: this.parent,
      project: 'console',
    })
  },
  beforeDestroy() {
    this.form.number.destroy()
    this.form.expiry.destroy()
    this.form.cvc.destroy()
    this.$gtm.trackEvent({
      event: 'card_form_close',
      event_source: this.parent,
      project: 'console',
    })
  },
}
</script>

<style lang="scss" scoped>
.field {
  @apply border rounded p-2 bg-white;
}
</style>
