<template>
  <div class="my-5 mr-5">
    <div v-if="store.subscription_slug === 'free'">
      <p>You have a free Subscription</p>
      <div class="flex flex-wrap mt-5 gap-3">
        <the-button
          @click.stop="$emit('updateStep', { step, index, incremental: -1 })"
          inner-class="w-40"
          secondary
        >
          Back
        </the-button>
        <the-button
          @click.stop="$emit('updateStep', { step, index })"
          inner-class="w-40"
          primary
        >
          Next
        </the-button>
      </div>
    </div>
    <div v-else-if="!cards.length && cards">
      <p>
        You will <span class="font-bold">not be charged</span> until 30 days after your trial expires. You can cancel
        trial at any time and won’t be charged.
      </p>
      <card-form
        @submitted="onCardCreated({ step, index })"
        :parent="'billing'"
      >
        <template #action-btn="{ submit, formReady, creatingCard }">
          <div class="flex flex-wrap mt-5 gap-3">
            <the-button
              @click.stop="$emit('updateStep', { step, index, incremental: -1 })"
              inner-class="w-40"
              secondary
            >
              Back
            </the-button>

            <the-button
              v-if="!step.completed && checkIfLastStep"
              @click="submit"
              :disabled="!formReady || creatingCard"
              primary
              id="creditcard_next"
              inner-class="w-40"
            >
              Complete
              <template #icon>
                <ic-check :size="20" />
              </template>
            </the-button>
            <the-button
              v-else
              inner-class="w-40"
              @click="submit"
              :disabled="!formReady || creatingCard"
              id="creditcard_next"
              primary
            >
              Next
            </the-button>
          </div>
        </template>
      </card-form>
    </div>
    <div v-else>
      <span>
        You successfully added a credit card. Go to Account > Billing > Payment methods to manage your credit cards.
      </span>
      <bid-link
        :router-link="true"
        href-link="account:billing:cards"
      >
        <template #text> click here </template>
        <template #icon>
          <ic-link />
        </template>
      </bid-link>
      <div class="flex flex-wrap mt-5 gap-3">
        <the-button
          @click.stop="$emit('updateStep', { step, index, incremental: -1 })"
          inner-class="w-40"
          secondary
        >
          Back
        </the-button>
        <the-button
          @click.stop="$emit('updateStep', { step, index })"
          inner-class="w-40"
          primary
        >
          Next
        </the-button>
      </div>
    </div>
  </div>
</template>

<script>
import CardForm from '@/components/account/CardForm'
import { mapGetters } from 'vuex'
import BidLink from '@/components/base/BidLink'
import IcLink from 'vue-material-design-icons/OpenInNew'
import IcCheck from 'vue-material-design-icons/Check'

export default {
  name: 'CreditCard',
  components: { BidLink, CardForm, IcLink, IcCheck },
  props: {
    step: {
      type: Object,
    },
    index: {
      type: Number,
    },
    checkIfLastStep: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      cards: 'card/getCards',
      store: 'store/selectedStore',
    }),
  },
  methods: {
    onCardCreated({ step, index }) {
      this.$gtm.trackEvent({
        event: 'onboarding_credit_card',
        event_source: 'onboarding',
        project: 'console',
      })
      this.$emit('updateStep', { step, index })
    },
  },
}
</script>
